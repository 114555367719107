<template>
  <section class="intro-x">
    <div class="w-full mt-3">
      <back-button />
    </div>
    <Form
    ref="formArticle"
    :validation-schema="schema"
    v-slot="{errors}"
    @submit="handleSubmit"
    >
      <div class="w-full flex flex-col box shadow-md p-4 my-3">
        <div class="w-full mb-2">
          <h4 class="font-bold text-lg">Producto</h4>
        </div>
        <!-- Foto del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="container xl:w-1/3">
            <div id="slim" class="slim" style="height:250px; width:250px;">
                <input type="file" id="file" name="slim[]"/>
            </div>
          </div>
        </div>
        <!-- Nombre del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="" class="capitalize">Nombre</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            v-model="model.name"
            :class="{'border-red-400': errors.name}"
            name="name"
            type="text"
            class="form-control"
            />
            <ErrorMessage name="name" class="text-red-400" />
          </div>
        </div>

        <!-- codigo de barrras del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="code" class="capitalize">Codigo </label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            id="code"
            v-model="model.code"
            :class="{'border-red-400': errors.code}"
            name="code"
            type="text"
            class="form-control"
            maxlength="140"
            />
            <ErrorMessage name="code" class="text-red-400" />
          </div>
        </div>

        <!-- codigo de barrras del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="codeBar" class="capitalize">Codigo de barras </label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            id="codeBar"
            v-model="model.codeBar"
            :class="{'border-red-400': errors.codeBar}"
            name="codeBar"
            type="text"
            class="form-control"
            maxlength="140"
            />
            <ErrorMessage name="codeBar" class="text-red-400" />
          </div>
        </div>

        <!-- Descripcion del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="descripcion" class="capitalize">Descripcion</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            v-model="model.description"
            id="descripcion"
            name="description"
            rows="3"
            class="form-control"
            as="textarea"
            />
            <ErrorMessage name="descripcion" class="text-red-400" />
          </div>
        </div>

        <!-- Categoria del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="descripcion" class="capitalize">Categoria</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            as="select"
            name="category_id"
            v-model="model.category_id"
            id="categoria"
            class="form-control capitalize"
            :class="{'border-red-400': errors.category_id}"
            >
              <option value="">Seleccionar</option>
              <option
              v-for="(row,k) in getCategorias"
              :key="k"
              :value="row.id"
              class="capitalize"
              >
                {{row.name}}
              </option>
            </Field>
            <ErrorMessage name="category_id" class="text-red-400" />
          </div>
        </div>

         <!-- Sub Categoria del producto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="descripcion" class="capitalize">Subcategoria</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            as="select"
            name="sub_category_id"
            v-model="model.sub_category_id"
            class="form-control"
            :class="{'border-red-400': errors.category_id}"
            >
              <option :value="0">Seleccionar</option>
              <option
              v-for="(row,k) in getSubCategorias"
              :key="k"
              :value="row.id"
              >
                {{row.name}}
              </option>
            </Field>
            <ErrorMessage name="sub_category_id" class="text-red-400" />
          </div>
        </div>

        <!-- Unidad de medida -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="descripcion" class="capitalize">Unidad de <br class="hidden lg:block"> medida base</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            as="select"
            name="unit_id"
            v-model="model.unit_id"
            id="unidadMedida"
            class="form-control"
            :class="{'border-red-400': errors.unit_id}"
            @change="selectedUnit($event.target.value)">
            >
              <option :value="null">Seleccionar</option>
              <option
              v-for="(row,k) in getUnidades"
              :key="k"
              :value="row.id"
              >
                {{row.name}}
              </option>
            </Field>
            <ErrorMessage name="unit_id" class="text-red-400" />
          </div>
        </div>

        <!-- Impuesto -->
        <div class="w-full flex flex-col xl:flex-row items-center mb-2">
          <div class="w-full xl:w-1/3">
            <label for="descripcion" class="capitalize">Impuesto</label>
          </div>
          <div class="w-full xl:w-2/3">
            <Field
            as="select"
            name="tax_id"
            v-model="model.tax_id"
            id="selImpuesto"
            class="form-control"
            :class="{'border-red-400': errors.tax_id}"
            >
              <option :value="null">Seleccionar</option>
              <option
              v-for="(row,k) in getImpuestos"
              :key="k"
              :value="row.id"
              >
                {{row.name}}
              </option>
            </Field>
            <ErrorMessage name="tax_id" class="text-red-400" />
          </div>
        </div>

        <!-- stock minimo -->
        <div class="w-full mt-2 mb-2">
          <div class="flex items-center">
            <div class="w-1/3">
              <label for="">Stock minimo</label>
            </div>
            <div class="w-2/3">
              <Field
              v-model="model.minimum_stock"
              name="minimum_stock"
              type="number"
              class="form-control"
              />
              <ErrorMessage name="minimum_stock" class="text-red-400" />
            </div>
          </div>
        </div>

        <!-- habilitado ? -->
        <div class="w-full mt-2 mb-2">
          <div class="flex items-center">
            <div class="w-1/3">
              <label for="">Habilitado</label>
            </div>
            <div class="w-2/3">
              <input
              v-model="model.state"
              id="show-example-5"
              data-target="#select-options"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-center my-3">
          <button
          type="submit"
          class="btn btn-primary block"
          >
            Guardar
          </button>
        </div>

      </div>
    </Form>
  </section>
</template>

<script>
import getFilesService from '../../../../services/getFilesService'
import saveFilesService from '../../../../services/saveFilesService'
import articleActualizar from './services/articleActualizar'
import articleBuscar from './services/articleBuscar'
import presentacionBuscar from './services/presentacionBuscar'
import createArticleInventary from '../../../../services/createArticleInventary'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as Yup from 'yup'
import { computed, onMounted, ref, watch } from 'vue'
import { confirm, messageWarning, messageSuccess } from '../../../../../../../libs/mensajes'
import { useRouter, useRoute } from 'vue-router'
import getUnits from '../../../../services/unitsGetAll'
import categoriasListar from '../../../../services/categoriasListar'
import impuestosListar from '../../../../services/impuestosListar'
import presentacionesListar from '../../../../services/presentacionesListar'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import Slim from '../../../../../../../libs/Slim/slim.module'
import configShops from '../../../../../../../config/shops'

export default {
  name: 'ArticleForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup () {
    /*
    * Instances
    */
    const router = useRouter()
    const route = useRoute()
    const isEdit = computed(() => {
      if (route.params.id) return true
      return false
    })

    /*
    * Refs and reactives
    */
    const model = ref({
      code: '',
      codeBar: null,
      name: '',
      description: '',
      minimum_stock: 0,
      category_id: null,
      sub_category_id: null,
      unit_id: null,
      tax_id: null,
      state: true,
      presentations: []
    })

    const instanceCrop = ref(null)

    const bandera = ref(0)

    const photo = ref(null)

    const isLoading = ref(false)

    const unidades = ref([])
    const categorias = ref([])

    const impuestos = ref([])
    const presentaciones = ref([])

    const formArticle = ref(null)

    const iPresentacion = ref({})

    /*
    * Computeds
    */
    const getCategorias = computed(() => {
      return categorias.value.filter(a => a.state)
    })

    const getSubCategorias = computed(() => {
      const finded = categorias.value.find(a => a.id === +(model.value.category_id))
      if (finded) return finded.categories
      return []
    })

    const getUnidades = computed(() => {
      return unidades.value.filter(a => a.state)
    })

    const getImpuestos = computed(() => {
      return impuestos.value.filter(a => a.state)
    })

    const getPresantaciones = computed(() => {
      return presentaciones.value.filter(a => a.state)
    })

    /*
    * Methods
    */
    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }
    const _getImagePath = ({ picture, name }) => {
      if (picture) return `${configShops.api}/files/${picture}`
      // return `https://ui-avatars.com/api/?background=125386&color=fff&name=${name}`
      return null
    }
    const modelFill = (value) => {
      model.value.code = value.code
      model.value.codeBar = value.codeBar
      model.value.name = value.name
      model.value.description = value.description
      model.value.category_id = value.category_id
      model.value.sub_category_id = value.sub_category_id
      model.value.unit_id = value.unit_id
      selectedUnit(value.unit_id)
      model.value.tax_id = value.tax_id
      model.value.minimum_stock = value.minimum_stock
      model.value.state = value.state
      photo.value = _getImagePath(value)
      initCrop()
    }
    const modelPresentationFill = (value) => {
      model.value.presentations = value
    }

    const fetchArticle = () => {
      return articleBuscar(route.params.id).then(({ status, data }) => {
        modelFill(data)
      }).catch(err => {
        console.error(err.message)
      })
    }
    // eslint-disable-next-line no-unused-vars
    const fetchPresentation = () => {
      return presentacionBuscar(route.params.id).then(({ status, data }) => {
        modelPresentationFill(data)
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getFile = (folder) => {
      return getFilesService({ folder })
    }
    const saveFile = () => {
      return photo.value ? saveFilesService({ files: instanceCrop.value.dataBase64.output.image }, { folder: 'articles' }) : { data: null }
    }

    const handleSubmit = async (values) => {
      const payload = { ...model.value }
      if (bandera.value > 1) {
        const { data: picture } = await saveFile()
        payload.picture = picture
      }
      if (isEdit.value) {
        articleActualizar(route.params.id, payload).then(({ status }) => {
          if (status === 200) {
            messageSuccess('Articulo actualizado')
            router.back()
          }
        }).catch(err => {
          console.error(err.message)
        })
        return false
      }
      toggleLoading()
      createArticleInventary(payload).then(() => {
        toggleLoading()
        cleanForm()
        confirm('¿Desea crear un nuevo articulo?', 'Artículo creado', 'success').then(({ isConfirmed }) => {
          if (!isConfirmed) router.back()
        })
      }).catch((err) => {
        toggleLoading()
        console.error(err)
        if (err.response.status === 400) {
          messageWarning(err.response.data.message)
        }
      })
    }

    const schema = computed(() =>
      Yup.object().shape({
        name: Yup.string().required().label('nombre'),
        code: Yup.string().required().label('codigo'),
        tax_id: Yup.string().required().nullable(true).label('impuesto'),
        minimum_stock: Yup.number().required().min(1).label('existencia minima'),
        category_id: Yup.string().required().nullable(true).label('categoria'),
        // sub_category_id: Yup.string().required().nullable(true).label('subcategoria'),
        unit_id: Yup.mixed().required().label('unidad de medida')
      })
    )

    const cleanForm = () => {
      model.value.code = ''
      model.value.codeBar = null
      model.value.name = ''
      model.value.description = ''
      model.value.minimum_stock = 0
      model.value.category_id = null
      model.value.sub_category_id = null
      model.value.unit_id = null
      model.value.tax_id = null
      model.value.state = true
      model.value.presentations = []
      formArticle.value.resetForm()
      instanceCrop.value.remove()
    }

    const fetchUnidades = () => {
      return getUnits().then(({ status, data }) => {
        if (status !== 200) return
        unidades.value = data
        return data
      }).catch((err) => {
        console.error(err.message)
      })
    }

    const fetchImpuestos = () => {
      return impuestosListar().then(({ status, data }) => {
        if (status !== 200) return false
        impuestos.value = data
        return data
      }).catch((err) => {
        console.error(err.message)
      })
    }

    const fetchPresentaciones = () => {
      return presentacionesListar().then(({ status, data }) => {
        if (status !== 200) return false
        presentaciones.value = data
        return data
      }).catch((err) => {
        console.error(err.message)
      })
    }

    const selectedUnit = (val) => {
      const unitFind = find(unidades.value, f => parseInt(f.id) === parseInt(val))
      presentaciones.value = unitFind ? orderBy(unitFind.presentations, ['name'], ['asc']) : []
    }

    const unidadesAdd = () => {
      model.value.presentaciones.push({
        name: '',
        value: null
      })
    }

    const unidadesRemove = (key) => {
      model.value.presentaciones.splice(key, 1)
    }

    const fetchCategorias = (value = null) => {
      return categoriasListar(value).then(({ status, data }) => {
        categorias.value = data
        return data
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getCountCategorias = (values = []) => {
      return values.length
    }

    const fetchSelects = () => {
      return Promise.all([
        fetchUnidades(),
        fetchCategorias(),
        fetchPresentaciones(),
        fetchImpuestos()
      ]).then(results => {
        console.log({ results })
      })
    }

    const onPresentationRemove = (index) => {
      model.value.presentations.splice(index, 1)
    }
    const initCrop = () => {
      const image = document.getElementById('slim')
      const option = {
        ratio: '1:1',
        download: false,
        willTransform: (data, ready) => {
          photo.value = data.input.image
          console.log('willTransform=====>', data)
          ready(data)
        },
        willRemove: (data, ready) => {
          photo.value = null
          ready(data)
        },
        label: 'Seleccione una imagen aquí.',
        buttonConfirmLabel: 'Ok',
        size: {
          height: 800,
          width: 800
        }
      }
      instanceCrop.value = new Slim(image, option)
      if (isEdit.value && photo.value) {
        instanceCrop.value.load(photo.value)
      } else {
        instanceCrop.value.remove()
        bandera.value++
      }
    }
    /*
    * hooks
    */
    onMounted(() => {
      fetchSelects().then(() => {
        if (isEdit.value) {
          fetchArticle()
        } else {
          initCrop()
        }
      })
    })

    /* Escuchas los cambios de las props */
    watch(() => photo.value, (newValue, oldValue) => {
      bandera.value++
    })

    return {
      getUnidades,
      getSubCategorias,
      getImpuestos,
      getPresantaciones,
      formArticle,
      getCategorias,
      unidadesRemove,
      unidadesAdd,
      unidades,
      model,
      handleSubmit,
      schema,
      fetchCategorias,
      categorias,
      getCountCategorias,
      isLoading,
      iPresentacion,
      selectedUnit,
      onPresentationRemove,
      getFile,
      bandera
    }
  }
}
</script>
<style lang="css">
@import "../../../../../../../libs/Slim/slim.min.css";
</style>
